import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface SearchResult {
  uuid: string;
  title: string;
  response: string;
  keywords: Array<string>;
  is_enabled: boolean;
  thumbnail: string;
  from: 'faq' | 'news';
  type: string;
  category: string;
  created_at?: string;
  publication_date?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private httpClient: HttpClient) { }

  search({ query, filter }: { query: string; filter?: 'faq' | 'news'; }): Observable<Array<SearchResult>> {
    let url: string;
    if (environment.application === 'maviepro') {
      url = `${environment.apiUrl}/typesense/search`;
      return this.httpClient.get<Array<SearchResult>>(url, {
        params: {
          query: query,
          filter: filter || 'faq'
        }
      });
    } else {
      url = `${environment.apiUrl}/elasticsearch/search`;
      return this.httpClient.post<Array<SearchResult>>(url, { query, filter });
    }
  }

  autocomplete(query: string): Observable<Array<string>> {
    let url: string;
    url = `${environment.apiUrl}/elasticsearch/autocomplete`;
    return this.httpClient.post<Array<string>>(url, { query });
  }

  getNotionSuggestions(query: string): Observable<Array<string>> {
    if (environment.application === 'maviepro') {
      const url = `${environment.apiUrl}/typesense/notions?query=${encodeURIComponent(query)}`;
      return this.httpClient.get<Array<string>>(url);
    }
    return this.autocomplete(query);
  }
}
