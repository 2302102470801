import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchResult, SearchService } from 'src/app/services/search.service';
import {
  debounce,
  distinctUntilChanged,
  filter,
  switchMap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { clearSearch, search } from 'src/app/store/actions/files.actions';
import { Observable, of, timer } from 'rxjs';
import {
  selectSearchIsLoading,
  selectSearchQuery,
  selectSearchResults,
} from 'src/app/store/selectors/files.selector';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mvp-search-files',
  templateUrl: './search-files.component.html',
})
export class SearchFilesComponent implements OnInit {
  @ViewChild('searchInput') searchInput: ElementRef;

  form: FormGroup = this.formBuilder.group({
    input: ['', [Validators.required, Validators.minLength(3)]],
  });
  isLoading$: Observable<boolean>;
  searchResults$: Observable<SearchResult[]>;
  searchValue$: Observable<string>;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private store: Store,
    private searchService: SearchService
  ) {
    this.searchResults$ = this.store.select(selectSearchResults);
    this.isLoading$ = this.store.select(selectSearchIsLoading);
    this.searchValue$ = this.store.select(selectSearchQuery);
  }

  isMaviepro = environment.application === 'maviepro';
  notionSuggestions: string[] = [];

  ngOnInit() {
    const specials = ["don", "pee", "perco", "cet", "rtt", "smic", "nao", "cdi", "cdd"];
    this.form.get('input').valueChanges.pipe(
      filter((value: string) => {
        if (!this.isMaviepro) {
          return true;
        }
        return value.length === 0 || value.length >= 5 || specials.includes(value.toLowerCase());
      }),
      debounce(() => timer(500)),
      distinctUntilChanged(),
      switchMap((query) => {
        if (!this.isMaviepro) {
          return of(query);
        }
        if (query.length >= 5 || specials.includes(query.toLowerCase())) {
          return this.searchNotions(query);
        }
        return of([]);
      })
    ).subscribe((result: any) => {
      if (this.isMaviepro && Array.isArray(result)) {
        this.notionSuggestions = result;
      }
    });
    /*
    this.form.get('input').valueChanges.pipe(
      filter((value: string) => value.length >= 3 || value.length === 0),
      debounce(() => timer(1500)),
      distinctUntilChanged(),
      switchMap((query) => {
        if (query.length === 0) {
          return of(clearSearch());
        }
        return of(search({ query }));
      })
    ).subscribe((action) => {
      this.store.dispatch(action);
    });
    */
  }
  searchNotions(query: string) {
    return this.searchService.getNotionSuggestions(query);
  }
  
  selectSuggestion(suggestion: string) {
    this.store.dispatch(search({ query: suggestion }));
    this.notionSuggestions = [];
    this.form.get('input').setValue(suggestion, { emitEvent: false });
  }

  onSubmit() {
    const query = this.form.get('input').value;
    if (query && query.length >= 3) {
      this.store.dispatch(search({ query }));
    }
  }
  
  resetSearchInput() {
    this.store.dispatch(clearSearch());
    this.notionSuggestions = [];
  }

  async goToResult(result: SearchResult) {
    switch (result.from) {
      case 'news':
        this.router.navigate([`article/${result.uuid}`]);
        break;
      case 'faq':
        this.router.navigate([`question/${result.uuid}`]);
        break;
      default:
        this.router.navigate([`question/${result.uuid}`]);
        break;
    }
  }

  @HostListener('document:touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    const inputElement = this.searchInput.nativeElement;
    if (
      event.target !== inputElement &&
      inputElement === document.activeElement
    ) {
      inputElement.blur();
    }
  }
}
