import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeIframe'
})
export class RemoveIframePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = value;

    const iframes = tempDiv.getElementsByTagName('iframe');
    for (let i = iframes.length - 1; i >= 0; i--) {
      iframes[i].parentNode?.removeChild(iframes[i]);
    }

    return tempDiv.innerHTML;
  }
}
