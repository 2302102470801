import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReadableDatePipe } from './readable-date.pipe';
import { ToFrenchDatePipe } from './to-french-date.pipe';
import { ToTimePipe } from './to-time.pipe';
import { CardNumberPipe } from './card-number.pipe';
import { CropPipe } from './crop.pipe';
import { DateDistancePipe } from './date-distance.pipe';
import { TextRenderHtmlPipe } from './text-render.pipe';
import { DateRelativePipe } from './date-relative.pipe';
import { FileSizePipe } from './filesize.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { RemoveIframePipe } from './remove-iframe.pipe';


@NgModule({
  declarations: [
    ReadableDatePipe,
    ToFrenchDatePipe,
    ToTimePipe,
    CardNumberPipe,
    CropPipe,
    DateDistancePipe,
    FileSizePipe,
    TextRenderHtmlPipe,
    DateRelativePipe,
    SafeHtmlPipe,
    RemoveIframePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ReadableDatePipe,
    ToFrenchDatePipe,
    ToTimePipe,
    CardNumberPipe,
    FileSizePipe,
    CropPipe,
    DateDistancePipe,
    TextRenderHtmlPipe,
    DateRelativePipe,
    SafeHtmlPipe,
    RemoveIframePipe
  ]
})
export class PipesModule { }
